@import "../components/colors";

#home-banner {
    // Extra small devices (portrait phones, less than 576px)
    &.section {
        padding: 0;
        .section-title {
            padding: 50px 15px;
            text-align: center;
            position: relative;
            top: 50%;
            transform: translateY(-50%);
            .btn {
                font-size: 0.9rem;
                font-weight: 500;
            }
            h2,
            span {
                font-weight: 500;
                font-size: 1.3rem;
            }
            p {
                font-size: 1rem;
                font-weight: 300;
            }
        }
        .section-content {
            img {
                width: 100%;
            }
        }
        .row > * {
            padding-right: 0;
            padding-left: 0;
        }
    }

    // Small devices (landscape phones, 576px and up)
    @media (min-width: 576px) {
    }

    // Medium devices (tablets, 768px and up)
    @media (min-width: 768px) {
        &.section {
            .section-title {
                text-align: left;
                .btn {
                    font-size: 1.4rem;
                    font-weight: 500;
                }
                h2,
                span {
                    font-size: 2rem;
                    font-weight: 600;
                }
            }
        }
    }

    // Large devices (desktops, 992px and up)
    @media (min-width: 992px) {
    }

    // Extra large devices (large desktops, 1200px and up)
    @media (min-width: 1200px) {
    }
}

#home-services {
    .section-title {
        text-align: left;
    }
    .section-content {
        .service-item {
            // height: 300px;
            // margin-bottom: 30px;
            // padding-top: 30px;
            // padding-bottom: 30px;
            .content {
                position: relative;
                top: 50%;
                transform: translateY(-50%);
                h4 {
                    font-weight: 300;
                    span {
                        color: $colorPrimary;
                    }
                }
                a {
                    text-decoration: none;
                    text-transform: uppercase;
                    color: $colorPrimary;
                }
            }
            .media {
                img {
                    width: 100%;
                    height: auto;
                }
            }
        }
    }
}

#home-milestones {
    .counterup-box {
        margin-bottom: 30px;
        border: 1px solid $colorLight;
        border-bottom: 4px solid $colorSecondary;
        padding: 20px;
        text-align: center;
        color: $colorLight;
        p {
            font-size: 14px;
        }
        h2 {
            font-weight: 400;
        }
    }
}

#home-service-charge {
    .section-title {
        padding-bottom: 30px;
        p {
            color: lighten($colorInverse, 10%);
        }
    }
    .card {
        border-radius: unset;
        .card-body {
            position: relative;
            top: 50%;
            transform: translateY(-50%);
        }
        p {
            text-transform: uppercase;
        }
        img {
            border-radius: unset;
        }
    }
}
#home-calculate-tax {
}
#home-insurance-rates {
    .card {
        background-color: $colorInverse;
        border-radius: unset;
        .card-body {
            color: $colorLight;
            h3 {
                font-weight: 300;
                span {
                    color: $colorSecondary;
                }
            }
            table {
                color: inherit;
            }
        }
    }
}

#home-testimonials {
    .item {
        background-color: $colorFaded;
        padding: 0;
        border-radius: unset;
        &:first-child {
            img {
                width: 100%;
                height: auto;
                border-radius: unset;
                border-right: 3px solid $colorSecondary;
            }
        }
        .caption {
            text-align: left;
            h4 {
                font-weight: 300;
            }
            p {
                padding-left: 0;
            }
            .card-body {
                height: auto;
            }
        }
    }
}
#home-collection-points {
    .list-container {
        .list-group {
            height: 350px;
            overflow-y: auto;
            .list-group-item {
                border: unset;
                border-radius: unset;
                border-right: 0.45rem solid $colorSecondary;
                margin-bottom: 0.3rem;
                h4 {
                    font-weight: 500;
                }
            }
        }
    }
    .collection-points-map {
        width: 100%;
        height: 350px;
    }
    // 768px and up
    @media (min-width: 768px) {
        .collection-points-map {
            width: 100%;
            height: 100%;
        }
    }
    // Small devices (landscape phones, 576px and up)
    @media (min-width: 576px) {
        .list-container {
            overflow: hidden;
            .list-group {
                height: 519px;
                overflow-y: scroll;
                margin-right: -50px;
                padding-right: 50px;
                .list-group-item {
                    border-radius: unset;
                    border-right: 7px solid $colorSecondary;
                    margin-bottom: 2px;
                    h4 {
                        font-weight: 400;
                    }
                }
            }
        }
    }
}
#home-blog {
    .card {
        &.card-light {
            border-radius: unset;
            img {
                border-radius: unset;
            }
            .caption {
                &:before {
                    border-top: unset;
                }
                a {
                    text-decoration: none;
                }
                .card-title {
                    color: $colorInverse;
                    font-size: 20px;
                    font-weight: 500;
                    &:hover,
                    &:focus {
                        color: $colorPrimary;
                    }
                }
                .card-body {
                    font-size: 16px;
                }
                .card-footer {
                    background-color: unset;
                    border-color: transparent;
                    font-size: 16px;
                    font-weight: 400;
                    padding-top: 0 !important;
                    padding-bottom: 0;
                    margin-bottom: 0;
                }
            }
        }
    }
}
#home-office-hours {
    .section-title,
    .section-content {
        text-align: left !important;
        a {
            text-decoration: none;
            color: $colorInverse;
        }
    }
}
#comprehensive-insurance-modal {
}
